@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	p {
		@apply mb-8 text-xl font-light leading-tight tracking-tight text-gray-900 antialiased lg:text-2xl;
	}
	.btn {
		@apply block rounded-3xl bg-gradient-to-br px-5 pt-3 pb-2.5 font-bold leading-5 ring-2 ring-inset drop-shadow-xl transition-all hover:rotate-1 hover:scale-110;
	}
	.flex-center {
		@apply flex items-center justify-center;
	}
	.flex-between {
		@apply flex items-center justify-between;
	}
	.ionite {
		@apply from-green-400 via-sky-500 to-green-400;
	}
	.amplified {
		@apply from-orange-600 via-yellow-500 to-orange-600;
	}
}
button:focus {
	outline: 0 !important;
}
html {
	scroll-behavior: smooth;
}
